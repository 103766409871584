<template>
  <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6" style="min-height: 500px;">
    <Table
      :name="name"
      :total="total"
      :fields="fields"
      :items="sentRequest"
      :showTotal="false"
      :hasFilter="false"
      :hasSearch="true"
      :perPage="10"
      :isBusy="isBusy"
      @changedPage="setPage"
      @setSearch="setSearch"
    >
      <template #actionsCustom="row">
        <b-button @click.prevent="cancel(row.row)" size="sm" variant="primary" :disabled="row.row.item.status !== 'Pending'">
          <span>{{ $t('global.cancel') }}</span>
        </b-button>
      </template>

      <template #contactInfo="row">
        <div class="fd-w-full fd-flex fd-items-center">
          <span class="fd-block fd-mr-2">{{ getName(row.row.item.contact_info) }}</span>
          <SvgLoader :id="row.row.item.id.toString()" :name="'sms2'" class="fd-cursor-pointer" />
          <b-tooltip :target="row.row.item.id.toString()" placement="bottom" boundary="document">
            <span class="fd-text-xs fd-font-normal">{{ row.row.item.contact_info.email }}</span>
          </b-tooltip>
        </div>
      </template>
      <template #invitedAt="row">
        <div class="fd-w-full fd-flex fd-items-center">
          <span class="fd-block fd-mr-3">{{ row.row.item.invited_at.split('T')[0] }}</span>
          <span class="fd-block">{{ row.row.item.invited_at.split('T')[1] }}</span>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import Table from "@/views/components/global/Table";
import Template from "@/views/pages/projectManagement/projects/Template";
import { BButton, BTooltip } from 'bootstrap-vue'
import {mapGetters} from "vuex";

export default {
  name: "SentRequest",
  components: {Template, Table, BButton, BTooltip},
  data() {
    return {
      loading: false,
      search: ''
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    this.getData()
  },
  watch: {
    search: {
      handler(newVal) {
        let data = {
          id: this.$route.params.id,
        }
        if (newVal) {
          data.search = newVal
        }
        this.getData(data)
      }
    }
  },
  computed: {
    ...mapGetters({
      sentRequest: 'rs/sentRequest',
      total: 'rs/sentRequestTotal'
    }),
    fields() {
      return [
        { key: 'name', label: this.$t('table.rsName'), sortable: true, sortDirection: 'desc', thStyle: { width: "20%" } },
        { key: 'business_id', label: this.$t('table.businessId'), thStyle: { width: "20%" } },
        { key: 'contact_info', label: this.$t('table.contactInfo'), thStyle: { width: "20%" } },
        {
          key: 'invited_at',
          label: this.$t('table.invitedAt'),
          thStyle: { width: "15%" }
          },
        { key: 'status', label: this.$t('table.status'), thStyle: { width: "15%" } },
        { key: 'actions', label: this.$t('table.actions'), class: 'text-right', thStyle: { width: "11%" } }
      ]
    },
    name() {
      return this.$t('navbar.sentRequest')
    },
    isBusy() {
      return this.loading
    }
  },
  methods: {
    getData(data = {}) {
      this.loading = true

      this.$store.dispatch('rs/getSentRequest', data).finally(() => {
        this.loading = false
      })
    },
    setFilters(filters) {
      console.log(filters)
    },
    setPage(number) {
      let data = {
        id: this.$route.params.id,
        number
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setSearch(search) {
      this.search = search
    },
    getName(contact) {
      return contact.first_name + ' ' + contact.last_name
    },
    cancel(row) {
      this.$store.dispatch('rs/updateRequest', {
        id: row.item.id,
        data: {
          status: 'cancel'
        }
      })
        .then(() => {
          this.getData()
        })
    }
  }
}
</script>

<style scoped>

</style>